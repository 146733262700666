@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .screen {
    @apply bg-gray-200 flex flex-col w-screen min-h-screen;
  }
  .backdrop {
    @apply flex flex-col w-full h-full sm:px-5 flex-grow;
  }
  .page {
    @apply z-10 p-5 flex flex-col w-full h-full flex-grow my-5 mx-auto bg-white shadow-lg max-w-5xl sm:rounded-md;
  }
  .header-backdrop {
    @apply sticky top-0 z-20 shadow-lg bg-white text-black sm:px-5;
  }
  .header-content {
    @apply flex h-14 max-w-5xl mx-auto uppercase text-sm items-center justify-between;
  }
  .header-active {
    @apply whitespace-nowrap flex h-14 w-full md:w-fit items-center text-white justify-end md:justify-center bg-orange-500 px-3 py-3 md:py-2;
  }
  .header-inactive {
    @apply whitespace-nowrap flex h-14 w-full md:w-fit items-center text-gray-400 justify-end md:justify-center cursor-pointer hover:bg-orange-400 hover:text-black px-3 py-3 md:py-2;
  }
  .footer-link {
    @apply hover:underline cursor-pointer hover:underline-offset-4 hover:text-gray-400;
  }

  .btn {
    @apply flex items-center justify-center px-3 py-2 hover:opacity-50 rounded-md space-x-1 disabled:opacity-50;
  }
  .btn-primary {
    @apply text-white bg-orange-400;
  }
  .btn-secondary {
    @apply text-white bg-orange-600;
  }
  .form-wrapper {
    @apply flex flex-col space-y-3;
  }
  .form-wrapper form {
    @apply flex flex-col space-y-3;
  }
  .form-wrapper label {
    @apply flex flex-col space-y-1;
  }
  .form-wrapper input,
  .form-wrapper textarea {
    @apply px-3 py-2 outline-none border rounded-md;
  }
  .form-wrapper button {
    @apply mr-auto py-2 px-3 bg-orange-500 text-white rounded-md cursor-pointer disabled:opacity-50 hover:opacity-50;
  }
  .response {
    @apply p-3 bg-orange-400 bg-opacity-20 my-3 rounded-md;
  }
  .displayImage {
    @apply w-full object-contain py-3;
  }

  .text-wrapper {
    @apply flex flex-col space-y-3;
  }
  .text-wrapper h3 {
    @apply text-2xl;
  }
  .text-wrapper h4 {
    @apply text-lg;
  }
  .text-wrapper ul {
    @apply list-disc mx-4 space-y-3;
  }
}
